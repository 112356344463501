import React, { useEffect } from 'react';
import useFetch from 'use-http';
import { useQuery, gql } from '@apollo/client';
import { differenceInWeeks, differenceInYears } from 'date-fns';
import { makeName, externalLinks, initials } from 'src/web/utility';

export const SESSION_INPUTS = gql`
  query SessionInputs2(
    $id: ID
    $input: SearchPersonReferenceRequest!
    $applicationsInput: ApplicationsInput
    $policiesInput: PoliciesInput
  ) {
    searchPersonReference(input: $input) {
      edges {
        id
        personReference {
          id
          givenName
          familyName
          healthApplicantEmail
          healthApplicantGivenName
          healthApplicantFamilyName
          isDeleted

          payrollProductTier
          ipAddress
        }
      }
    }
    viewerTwo(id: $id) {
      id
      agreements {
        isPrivacyAuthorizeAgree
      }
      consentRequest(consentType: BROKER) {
        id
        token
        status
        expiresAt
        agreedAt
      }
      health {
        applications(input: $applicationsInput) {
          id
          __typename
        }
        policies(input: $policiesInput) {
          id
          __typename
        }
      }
    }
    viewer(id: $id) {
      id
      incomeState
      user(nocache: true) {
        id
        email
        dob
        residenceState
        filingStatus
        phoneNumber
        workType
        signupCode
        partnerParam
        nickname
        givenName
        middleName
        familyName
        createdOn
        ssn
        signupContext
        avatarImage
        payrollProductTier
        jobTitle
        intercomID
        legalAddress {
          street1
          street2
          city
          state
          zip
          countyfips
        }
        mailingAddress {
          state
          zip
          countyfips
        }
        ein
        dba
      }
    }
  }
`;

const useUserSummary = ({ id }) => {
  const { data, loading, error } = useQuery(SESSION_INPUTS, {
    skip: !id,
    variables: {
      id,
      input: {
        id,
        pagination: {
          pageNumber: 1,
          pageSize: 1,
        },
      },
      applicationsInput: {},
      policiesInput: {},
    },
  });

  const pv = data?.searchPersonReference?.edges?.[0]?.personReference;
  const v = data?.viewer;
  const v2 = data?.viewerTwo;
  const u = v?.user;

  const ccs = [...(v?.contacts || [])];

  // personal info
  const givenName = u?.givenName || pv?.givenName || pv?.healthApplicantGivenName || '';
  const nickname = u?.nickname || '';
  const middleName = u?.middleName || '';
  const familyName = u?.familyName || pv?.familyName || pv?.healthApplicantFamilyName;
  const name = makeName({ givenName, nickname, familyName });
  const dob = u?.dob;
  const age = differenceInYears(Date.now(), Date.parse(dob));
  const ssn = u?.fullSSN || u?.ssn ? `•••–••–${u?.ssn}` : null;
  const phone = u?.phoneNumber;
  const email = u?.email || pv?.healthApplicantEmail;
  const isDeleted = pv?.isDeleted;
  const signupContext = u?.signupContext || 'ONBOARDING';

  // family
  const spouse = /MARRIED/.test(u?.filingStatus) || [...ccs]?.filter(c => c?.relation === 'SPOUSE')?.[0];
  const isMarried = /MARRIED/.test(u?.filingStatus) || !!spouse;
  const filingStatus = u?.filingStatus;
  const children = [...ccs]?.filter(c => c?.relation === 'CHILD' || c?.relation === 'OTHER_DEPENDENT');

  // catch info

  const joinDate = u?.createdOn;
  const workType = u?.workType;
  const numGoals = v?.goals?.length;
  const jobTitle = u?.jobTitle;
  const signupCode = u?.signupCode;
  const partnerParam = u?.partnerParam;
  const kycStatus = u?.kycSavings?.status;
  const _income =
    (!!isMarried && v?.spouseIncome ? v?.spouseIncome : 0) +
    (u?.workType === 'WORK_TYPE_1099'
      ? v?.income?.estimated1099Income
      : u?.workType === 'WORK_TYPE_W2'
      ? v?.income?.estimatedW2Income
      : v?.income?.estimated1099Income + v?.income?.estimatedW2Income);
  const income = _income;

  const incomeAlt = _income;

  // location
  const fips = u?.legalAddress?.countyfips || u?.mailingAddress?.countyfips;
  const zip = u?.legalAddress?.zip || u?.mailingAddress?.zip;
  const state = u?.legalAddress?.state || u?.mailingAddress?.state || u?.residenceState || u?.incomeState;

  const { get: getFips, data: counties, loading: zipLoading } = useFetch(
    `https://marketplace.api.healthcare.gov/api/v1/counties/by/zip/${zip}?apikey=uplvuvRrQRzAWlleHNXfPcN5qkdnVJBg&year=2021`,
  );
  useEffect(() => {
    if (zip?.length === 5 && !fips) getFips();
  }, [fips, getFips, zip]);

  if (!id) return {};

  // @todo
  const isLocked = false;
  const preventIdentityLock = v?.savingsAccount?.preventIdentityLock;
  const isTradingPaused = !!v?.wealthAccount?.isDisabled;

  const incomeSeen = v?.planHistorySummary?.grossIncome;
  const yearsHere = differenceInWeeks(Date.now(), Date.parse(joinDate)) / 52;
  const incomeProjected = incomeSeen / yearsHere;
  const links = externalLinks(u?.id, u?.intercomID);
  const balance = v?.planHistorySummary?.balances?.reduce((acc, bal) => acc + parseInt(bal.balanceAll), 0);
  const tier = pv?.payrollProductTier;
  const brokerConsent = v2?.agreements?.isPrivacyAuthorizeAgree;
  const consentRequest = v2?.consentRequest;

  return {
    id: v?.id,
    name,
    initials: initials({ givenName, nickname, familyName }),
    avatar: u?.avatarImage,
    givenName,
    familyName,
    firstName: nickname || givenName,
    middleName,
    lastName: familyName,
    nickname,
    dob,
    age,
    ssn,
    joinDate,
    workType,
    numGoals,
    user: u,
    jobTitle,
    joinCode: signupCode,
    partnerParam,
    kycStatus,
    income: Math.round(income),
    brokerConsent,
    consentRequest,

    incomeAlt: Math.round(incomeAlt),
    incomeSeen: Math.round(v?.planHistorySummary?.grossIncome),
    incomeProjected: Math.round(incomeProjected / 100) * 100,
    saved: v?.planHistorySummary?.totalSavingsAll,
    spouse,
    isMarried,
    filingStatus,
    notes: v?.savingsAccount?.notes,
    children,
    legalAddress: u?.legalAddress,
    fips: fips || counties?.counties?.[0]?.fips,
    zip,
    state,
    loading,
    error,
    phone,
    email,
    isLocked,
    isDeleted,
    preventIdentityLock,
    isTradingPaused,
    balance,
    hasRestrictions: isLocked || isTradingPaused,
    bankPartnerId: v?.savingsAccount?.id,
    wealthId: v?.wealthAccount?.folioId,
    accountNumber: v?.savingsAccount?.accountNumber,
    routingNumber: v?.savingsAccount?.routingNumber,

    urlIntercom: links?.intercom,
    urlCustomer: links?.customer,
    urlLogrocket: links?.logrocket,
    signupContext,
    tier,
    urlOpenphone: 'https://my.openphone.co/inbox/PNuagd9Cye/',
    urlMixpanel: `https://mixpanel.com/project/2574801/view/3114325/app/profile#distinct_id=${v?.id}`,
    urlBond:
      v?.savingsAccount?.savingsProviderType !== 'UNIT'
        ? `https://app.bond.tech/app/operations/transactionquerying`
        : null,
    urlUnit:
      v?.savingsAccount?.savingsProviderType === 'UNIT'
        ? `https://app.unit.co/individual/${v?.savingsAccount?.id}#`
        : null,
    bankPartner: v?.savingsAccountMetadata?.savingsProviderType?.toProperCase(),
    urlBankPartner:
      v?.savingsAccount?.savingsProviderType === 'BOND'
        ? `https://app.bond.tech/app/operations/transactionquerying`
        : v?.savingsAccount?.savingsProviderType === 'UNIT'
        ? `https://app.unit.co/individual/${v?.savingsAccount?.id}#`
        : null,
    urlAbound: 'https://dashboard.withabound.com/item-explorer',
    urlCastle: `https://dashboard.castle.io/v2/users/${v?.id}`,
    ipAddress: pv?.ipAddress,
  };
};

export default useUserSummary;
